import React, { CSSProperties, PropsWithChildren } from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { Grid } from 'components';
import styles from './team-page.module.scss';

export function TeamBody(props: React.PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.team} {...props} />;
}

export function TeamData(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <Grid className={styles.teamData} {...props} />;
}

export function TeamDataBody(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.teamData__body} {...props} />;
}

export function TeamDataItem(props: PropsWithChildren<{ style?: CSSProperties }>) {
    return <div className={styles.teamData__item}>
        <div className={styles.teamData__itemBox} {...props} />
    </div>;
}


export function TeamMasonry(props: PropsWithChildren<{ style?: CSSProperties }>): JSX.Element {
    return <Grid>
        <div className={styles.teamMasonry} {...props} />
    </Grid>;
}


export function TeamMasonryItem(props: PropsWithChildren<{ style?: CSSProperties; className?: string; }>): JSX.Element {
    return <div className={styles.teamMasonry__item} {...props} />;
}


type MemberDataProps = PropsWithChildren<{
    style?: CSSProperties;
    image: FluidObject;
    name: string;
    position: string;
    linkedin?: string;
}>;


export function TeamMasonryMember(props: MemberDataProps): JSX.Element {
    const { image, name, position, linkedin, ...elseProps } = props;

    return (
        <div className={styles.teamMasonry__member} {...elseProps}>
            <Img fluid={image} />

            <div className={styles.teamMasonry__memberAbout}>
                <h4>{name}</h4>
                <span>{position}</span>

                {linkedin && (
                    <a href={linkedin} target="_blank" className={styles.teamMasonry__memberAboutLinkedin}>
                        <LinkedinSVG />
                    </a>
                )}
            </div>
        </div>
    );
}


export function LinkedinSVG() {
    return (
        <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 4a2 2 0 012 2v16a2 2 0 01-2 2H6a2 2 0 01-2-2V6c0-1.1.9-2 2-2h16zm-11.25 7.3H8.29v6.92h2.46V11.3zm6.03-.16c-1.3 0-1.89.67-2.21 1.14v-.98H12.1c.03.65 0 6.92 0 6.92h2.46v-3.86c0-.2.01-.41.08-.56.18-.42.58-.84 1.26-.84.89 0 1.24.63 1.24 1.56v3.7h2.46v-3.96c0-2.13-1.21-3.12-2.83-3.12zM9.54 7.97c-.84 0-1.4.51-1.4 1.2 0 .66.54 1.19 1.37 1.19h.01c.86 0 1.4-.53 1.4-1.2-.02-.68-.54-1.2-1.38-1.2z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </svg>
    );
}


export function TeamMasonryBio(props: PropsWithChildren<any>): JSX.Element {
    return <p className={styles.teamMasonry__memberBio} {...props} />;
}


export function TeamCooperate(props: any): JSX.Element {
    return <TeamMasonryItem className={styles.teamCooperate} {...props}>
        <div className={styles.teamCooperate__box}>
            <h4>We cooperate with aspiring people</h4>
            <p>Let's connect now</p>

            <a href="mailto:letscollaborate@p1k.org">letscollaborate@p1k.org</a>
        </div>
    </TeamMasonryItem>;
}
